import { HStack, Text, chakra, Stack, Container } from "@chakra-ui/react";
import React from "react";
import MasterLayout from "./master";
import { AiFillLinkedin, AiFillInstagram } from "react-icons/ai";
import { BsTwitter, BsFacebook } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
// import SEO from "../components/seo"

export default function OneSchool(props) {
  const { pageContext } = props;
  const { school } = pageContext;

  // const stateManagement = school.details
  //   .split(":")[1]
  //   .split("National Mgmt")[0]
  //   .trim();
  // const nationalManagement = school.details
  //   .split(":")[2]
  //   .split("School Category")[0]
  //   .trim();
  const schoolCategory = school.details
    .split(":")[3]
    .split("School Type")[0]
    .trim();
  const schoolType = school.details.split(":")[4].split("Pin Code")[0].trim();

  return (
    <MasterLayout>
      {/* <SEO title="SunoKitaab NCERT Audiobook For K3, K10, K12, NEET, JEE, UPSC, Police Exams and Banks Exams" /> */}
      <Stack
        backgroundImage={
          "linear-gradient(to bottom, rgb(83 187 106), #53bb6a9e), url(https://sunokitaab.s3.ap-south-1.amazonaws.com/site-assets/school_bg.jpg)"
        }
        backgroundSize={{ lg: "cover", md: "cover", sm: "cover" }}
        backgroundPosition={"center"}
      >
        <Container maxW={'6xl'} py={12}>
        <chakra.h1
          lineHeight={1.2}
          fontSize={{ base: "xl", md: "2xl", lg: "4xl" }}
          color={"white"}
          fontWeight={"bold"}
        >
          {school.name}
        </chakra.h1>
        <Text color={"white"} fontWeight={600} fontSize={"2xl"}>
          {schoolCategory + ", " + schoolType}
        </Text>
        <Text pt={"4"} display={"flex"} alignItems={"center"} color="white">
          <ImLocation /> Address
        </Text>
        <chakra.h3 color={"white"} fontWeight={"bold"}>
          {`${school.state.name}, ${school.district.name}, ${school.block}, ${school.village}, ${school.pincode}`}
        </chakra.h3>
        <HStack pt={'4'} direction={"row"} spacing={6}>
          <a href="https://in.linkedin.com/" target={"_blank"}>
            <AiFillLinkedin color="white" size={"35px"} />
          </a>
          <a href="#" target={"_blank"}>
            <AiFillInstagram color="white" size={"35px"} href="#" />
          </a>
          <a href="#" target={"_blank"}>
            <BsTwitter color="white" size={"35px"} href="#" />
          </a>
          <a href="#" target={"_blank"}>
            <BsFacebook color="white" size={"35px"} href="#" />
          </a>
        </HStack>
        </Container>
      </Stack>
    </MasterLayout>
  );
}
